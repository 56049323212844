#designer-container {
	width: 100%; 
	height: 100%;
}

#designer-header {
    background: white;
	width: 100%; 
}
  
#designer-wrapper{
	width: 100%;
}

#designer-leftpane {
	max-width: 25%;
	margin-right: 5%;

	display: inline-block;
	vertical-align: top; 

	position: -webkit-sticky; /* Safari */
  	position: sticky;
  	top: 0;
}
  
#designer-content {
	width: 70%;
	margin-left:-4px;
	display: inline-block;
	vertical-align: top;
}
  
#designer-footer {
	height: 64px;
	width: 100%;
}


.dsddesigner {
	font-family: arial, Verdana,Helvetica,Arial,sans-serif;
	font-weight: normal;
	font-size: 100%;
	line-height: 1.4;
	color: #000;
}

.dsddesigner a {
	background: transparent;
	text-decoration: none;
	color: #005098;
}

.dsddesigner p {
	line-height: 1.5;
}

.dsddesigner p a, a.decorated {
	border-bottom: 1px dotted #005098;
}

.designer-title {
	font-size: 3.00em;
	line-height: 1.3;
	font-family: arial, Georgia,"Times New Roman",Times,serif;
	font-weight: normal;
}

.designer-processtitle {
	font-size: 2em;
	line-height: 1.2;
	font-family: arial, Georgia,"Times New Roman",Times,serif;
	font-weight: normal;
}

.designer-contentheading {
	font-size: 2em;
	line-height: 2;
	font-family: arial, Georgia,"Times New Roman",Times,serif;
	font-weight: normal;
}


.designer-sectionheading {
	font-size: 1.6em;
	line-height: 1.2;
	font-family: arial, Georgia,"Times New Roman",Times,serif;
	font-weight: normal;
}



.dsddesignerHTML h1 {
	color: #000;
	font-size: 1.4em;
	line-height: 1.15;
	font-family: arial, Georgia,"Times New Roman",Times,serif;
	font-weight: normal;
}


.dsddesignerHTML h2 {
	color: #000;
	font-size: 1.20em;
	line-height: 1.15;
	font-family: arial, Georgia,"Times New Roman",Times,serif;
	font-weight: normal;
	color: #000;
}

.dsddesignerHTML a {
	border-bottom: 1px dotted #005098;
}

.designer-breadcrumb{
	font-size: .85em;
	line-height: 2;
}

.designer-breadcrumb a {
	text-decoration: none;
	color: #005098;
	background: transparent;
}

.designer-sidemenu ul{

	display: block;
	list-style-type: disc;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 40px;

	list-style: none;
	margin: 0;

}

.designer-sidemenu li{
	line-height: 1.3;
	display: list-item;
	text-align: match-parent;
}

.designer-sidemenu a{
	background: transparent;
	text-decoration: none;
	color: #005098;
}


.designer-sidemenu li a{
	display: block;
/*	padding: .5em .5em .5em .75em; */
	padding: .2em .2em .2em 0.2em;
	border-left: 3px solid #fff;
	position: relative;
}

.designer-menuHighlight {
	border-color: #ffe153;
	color: #000;
	background-color: #f7f3f0;
}  
  
/* ---------------------------- */  
  
  #header {
    background: #ccc;
    padding: 20px;
    }
  
  #sidebar {
    float: left;
    width: 8%;
    background: #999;
    }
  
  #content {
    margin-left: 10%;
    }
  
  #footer {
    clear: right;
    background: #eee;
    padding: 20px;
    }