
body  
{  	
    font-family: "arial,lucida grande" ,tahoma,verdana ,sans-serif;  
    font-size: 14px;
    line-height:120%;
    color: #333333;  
}  
/* Default Anchor Style */  


table {
	
	border-collapse: collapse;
	
	border-top:0px; 
	border-bottom:0px;
	
	vertical-align:top;
	}

th {
	border-bottom: 1px solid #CCC;
	padding: 5px 0.5em;
	text-align: left;
	vertical-align:top;
	}



td {
	border-width: 1px;
/*	border-bottom: 1px solid #CCC; */
	padding: 5px 0.5em;
	vertical-align:top;
	}

.wait, .wait * {
	cursor: wait; 
	}

table.list tr {
	border-bottom: 1px solid #CCC;
	}

table.list th {
	max-width:100px;
	max-height:100px;
	overflow:auto;
	}


table.list td {
	max-width:300px;
	max-height:100px;
	overflow:auto;
	}


table.form th {
	max-width:100px;
	max-height:100px;
	overflow:auto;
	}


table.panel td {
    max-width: 1000px;
    word-wrap: break-word;    
    line-height: normal;
    
    
	}


td.adjacent {
	border-left: 1px solid #CCC;
	text-align: center;
	}


.tab { margin-left: 40px; }

.byimage {
	max-width: 140px;
	max-height: 50px;
}

.errorbox {
	background: #c51244 !important;
	padding: 10px !important;
	border-radius: 0 !important;
	position: relative; 
	display: inline-block !important;
	box-shadow: 1px 1px 1px #aaaaaa;
	margin-top: 10px;
  }


button.button-submit {
  background-color: green;
  width: 100px;
  white-space:normal;
  word-wrap:break-word;
/*    border: none; */
  color: white;
  padding: 15px 10px; 
  text-align: center; 
  text-decoration: none;
  display: inline-block; 
  font-size: 16px;
}

button.button-cancel {
    background-color: red;
	width: 100px;
	white-space:normal;
	word-wrap:break-word;
  /*    border: none; */
	color: white;
	padding: 15px 10px; 
	text-align: center; 
	text-decoration: none;
	display: inline-block; 
	font-size: 16px;
	}
  
  button.button-warning {
    background-color: red;
	width: 100px;
	white-space:normal;
	word-wrap:break-word;
/*    border: none; */
    color: white;
    padding: 15px 10px; 
    text-align: center; 
    text-decoration: none;
    display: inline-block; 
    font-size: 16px;
  }



h1, h2, h3, h4, h5{font-size:13px;color:#3b5998;}

h1 { font-size:24px;line-height: 30px; }
h2 { font-size:20px; }
h4 { font-size:11px; }
h5 { font-size:11px; }


form {
	padding: 1em;
	background: #f9f9f9;
	border: 1px solid #c1c1c1;
	margin-top: 2rem;
	margin-left: auto;
	margin-right: auto;
	padding: 1em;
}
.field-legend {
	float: left;
	width: 15%;
	margin-top: 6px;
	padding-right: 24px;
}
  
.field {
	float: left;
	max-width: 85%;
	margin-top: 6px;
  }

/* Clear floats after the columns */
.field-row::after {
	content: "";
	display: table;
	clear: both;
  }